<template>
    <div :class="{ filter: true, active }">
        <label @click="toggle">{{ label }}</label>
        <div>
            <slot />
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    export default {
        props: {
            label: {
                type: String,
                default: 'activator'
            },
            modelValue: Boolean
        },
        emits: ['update:modelValue'],
        setup( props, context )
        {
            let active = ref( props.modelValue );
            return {
                active,
                toggle: () => {
                    active.value = !active.value;
                    context.emit( 'update:modelValue', active );
                }
            };
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/scss/settings";
    .filter {
        padding: 0.5em;
        position:relative;
        label {
            border-bottom: 1px solid #666;
            width: 100%;
            display: inline-block;
            margin-bottom: 0.25em;
            cursor:pointer;
            &:hover {
                background-color: rgba( 255, 255, 255, 0.1 );
            }
        }
        &>div {
            display: none;
        }
        &:before {
            content: 'off';
            position: absolute;
            top: 0.5em;
            right: 0.5em;
        }
        &.active {
            &:before {
                content: 'on';
                color: lime;
            }
            &>div {
                display:block;
            }
        }
    }
</style>