<template>
    <div class="filters">
        <filter-activator label="Country" v-model="filterActive.Country">
            <multiselect v-model="countries" :options="countryOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Search" v-model="filterActive.Search">
            <input v-model="search" type="text" />
        </filter-activator>
        <filter-activator label="Division" v-model="filterActive.Division">
            <multiselect v-model="divisions" :options="divisionOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Phases" v-model="filterActive.Phases">
            <multiselect v-model="phases" :options="phaseOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Products" v-model="filterActive.Products">
            <multiselect v-model="products" :options="productOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Placement" v-model="filterActive.Publisher">
            <multiselect v-model="publisher" :options="publisherOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Content type" v-model="filterActive.ContentType">
            <multiselect v-model="contentType" :options="contentTypeOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Is live" v-model="filterActive.Active">
            <toggle v-model="active" id="active" :trueValue="true" :falseValue="false" />
        </filter-activator>
        <div class="info">
            {{ banners.length }} matches<br />
        </div>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect';
    import Toggle from '@vueform/toggle';
    // import Datepicker from 'vue3-datepicker';
    import FilterActivator from '@/components/FilterActivator';
    import { ref, watch, computed } from 'vue';
    import { useStore } from 'vuex';
    export default {
        components: {
            // Datepicker,
            Toggle,
            Multiselect,
            FilterActivator
        },
        /* props: {
            filters: {
                type: Array,
                default: () => { return []; }
            }
        }, */
        setup()
        {
            const store = useStore();

            const countries = ref( store.getters.filters.Country );
            const countryOptions = ref({ NL: 'NL', BE_NL: 'BE_NL', BE_FR: 'BE_FR' });
            const search = ref( store.getters.filters.Search );
            const divisions = ref( store.getters.filters.Division );
            const divisionOptions = ref({ HAD: 'HAD', IMD: 'IMD', AVD: 'AVD' });
            const phases = ref( store.getters.filters.Phases );
            const phaseOptions = ref({ cold: 'cold', warm: 'warm', hot: 'hot', loyal: 'loyal' });
            const products = ref( store.getters.filters.Products );
            const productOptions = ref({ BI: 'BI', REF: 'REF', HHP: 'HHP', AUDIO: 'AUDIO', TAB: 'TAB', TV: 'TV', VC: 'VC', WM: 'WM', WEA: 'WEA' });
            const publisher = ref( store.getters.filters.Publisher );
            const publisherOptions = ref({ facebook_feed: 'facebook_feed', facebook_stories: 'facebook_stories', facebook_instant_article: 'facebook_instant_article', facebook_explore: 'facebook_explore', instagram_feed: 'instagram_feed', instagram_stories: 'instagram_stories', instagram_instant_article: 'instagram_instant_article', instagram_explore: 'instagram_explore' });
            const contentType = ref( store.getters.filters.ContentType );
            const contentTypeOptions = ref({ share: 'share', video: 'video' });
            const active = ref( store.getters.filters.Active );

            const filterActive = computed( () => store.getters.filterActive );

            watch( filterActive,
                ( value ) => {
                    let filter = {};
                    for( let i in value )
                    {
                        filter[ i ] = value[i];
                    }
                    store.dispatch( 'setFilterActive', filter );
                }, { deep: true });

            watch( search,
                ( value ) => {
                    store.dispatch( 'setFilter', {
                        Search: value
                    });
                } );

            watch( countries,
                () => {
                    let filter = [];
                    for( let i in countries.value )
                    {
                        filter.push( countryOptions.value[ countries.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        Country: filter
                    });
                } );

            watch( divisions,
                () => {
                    let filter = [];
                    for( let i in divisions.value )
                    {
                        filter.push( divisionOptions.value[ divisions.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        Division: filter
                    });
                } );

            watch( phases,
                () => {
                    let filter = [];
                    for( let i in phases.value )
                    {
                        filter.push( phaseOptions.value[ phases.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        Phases: filter
                    });
                } );

            watch( products,
                () => {
                    let filter = [];
                    for( let i in products.value )
                    {
                        filter.push( productOptions.value[ products.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        Products: filter
                    });
                } );

            watch( publisher,
                () => {
                    let filter = [];
                    for( let i in publisher.value )
                    {
                        filter.push( publisherOptions.value[ publisher.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        Publisher: filter
                    });
                } );

            watch( contentType,
                () => {
                    let filter = [];
                    for( let i in contentType.value )
                    {
                        filter.push( contentTypeOptions.value[ contentType.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        ContentType: filter
                    });
                } );

            watch( active,
                () => {
                    store.dispatch( 'setFilter', {
                        Active: active.value
                    });
                });

            let banners = computed( () => store.getters.active );
            console.log( 'banners', banners );
            
            return {
                search,
                active,
                countries,
                countryOptions,
                divisions,
                divisionOptions,
                phases,
                phaseOptions,
                products,
                productOptions,
                publisher,
                publisherOptions,
                contentType,
                contentTypeOptions,
                banners,
                filterActive
            };
        }
    }
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style src="@vueform/toggle/themes/default.css"></style>
<style lang="scss">
    @import "@/assets/scss/settings";

    .datepick {
        width: 100%;
    }

    h3 {
        text-align: left;
        font-weight: normal;
        padding: 0.5em;
        margin: 0;
        margin-top: 1em;
        color: #bbb;
    }

    .info {
        padding: 0.5em 1em 0.5em 0.5em;
        color: #666;
        text-align: center;
    }

    .filter {
        position:relative;
        &>label {
            border-bottom: 1px solid #666;
            width: 100%;
            display: inline-block;
            margin-bottom: 0.25em;
        }
        &>div {
            display: none;
        }
        &:before {
            content: '+';
            position: absolute;
            top: 0.5em;
            right: 0.5em;
        }
        &.active {
            &:before {
                content: '-';
            }
            &>div {
                display:block;
            }
        }
        input {
            background: transparent;
            color: white;
            border: 1px solid white;
            border-radius: 5px;
            padding: 0.25em;
        }
    }
</style>