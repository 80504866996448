<template>
    <div :class="{ 'preview-pane': true, [viewType]: true }" ref="target" :style="{ width: frameWidth + 'px' }">
        <div :style="{ width: frameWidth + 'px', height: frameHeight + 'px' }" class="preview">
            <iframe ref="frame" :src="src" frameborder="0" :width="width" :height="height" v-if="targetRendered" v-show="targetIsVisible || forceView" scrolling="no"></iframe>
        </div>
        <div class="info" v-if="viewType === 'dco'">
            Unique Id: {{ data.UniqueID }} | Status: {{ data.Active === 'TRUE' ? 'Live' : 'Inactive' }}<br />
            Phase: {{ data.CampaignPhase }} | Division: {{ data.Division }}<br />
            Product: {{ data.Modelnamelong }} | Segment: {{ data.Segmentname }}<br />
            Rundate: {{ data.Startdate }} to {{ data.Enddate }}<br />
            <a :href="data.ExitUrl" target="_blank">URL:&nbsp;{{ data.ExitUrl }}</a>
            <img src="/svg/replay.svg" alt="" @click.prevent="reload" class="replay" />
        </div>
        <div class="info" v-if="viewType === 'social'">
            Type: {{ data['Publisher platform'] }} {{ data['Platform position'] }}<br />
            Impressions: {{ data['Impressions'] }} - 
            CTR: {{ data['CTR (all)'] }}<br />
            <a :href="data['Destination URL']" target="_blank">Destination URL &gt;</a>
        </div>
    </div>
</template>

<script>
    import { ref, onUpdated, onMounted, watch } from 'vue'
    import { useElementVisibility } from '@vueuse/core'
    import { useStore } from 'vuex';
    export default {
        components: {
        },
        props: {
            data: {
                type: Object,
                default: () => { return {}; }
            },
            forceView: {
                type: Boolean,
                default: false
            }
        },
        setup( props )
        {
            const store = useStore();
            const target = ref( null );
            const frame = ref( null );
            const targetIsVisible = useElementVisibility( target );
            const targetRendered = ref( props.forceView );
            let viewType = ref( store.getters.viewType );

            function start()
            {
                if( !frame.value ) return;
                frame.value.onload = () => {
                    frame.value.contentWindow._injectData = props.data;
                }
            }

            function reload()
            {
                frame.value.contentWindow.location.reload();
                console.log( props.data );
            }

            onUpdated( start );
            onMounted( start );

            
            watch( targetIsVisible,
                () => {
                    targetRendered.value = targetRendered.value || targetIsVisible.value;
                });

            let src = viewType.value === 'dco' ? '/templates/template-base-dc_studio_dco-' + props.data.Adsize + '/?_d=1' : props.data['Ad preview URL: desktop feed'];

            let width = props.data.Adsize ? props.data.Adsize.split('x')[0] : 502;
            let height = props.data.Adsize ? props.data.Adsize.split('x')[1] : 785;

            return {
                src,
                target,
                frame,
                targetIsVisible,
                targetRendered,
                width,
                height,
                frameWidth: viewType.value === 'social' ? width / 2 : width,
                frameHeight: viewType.value === 'social' ? height / 2 : height,
                start,
                reload,
                viewType
            }
        }
    }
</script>

<style scoped lang="scss">
    .preview-pane {
        display: inline-block;
        box-shadow: 0px 0px 15px rgba( 0, 0, 0, 0.1 );
        margin: 0.5em;
        .info {
            font-size: 10px;
            padding: 1em;
            position: relative;
            flex: 1;
            
            word-break: break-word;
            overflow: hidden;
            // text-overflow: ellipsis;
            a {
                color: black;
                text-decoration: none;
                display: inline-block;
                min-height: 5em;
            }
            .replay {
                position: absolute;
                top: 0.5em;
                right: 0.5em;
                width: 2em;
                cursor: pointer;
            }
        }
        .preview {
            background-color: lightgrey;
        }
        &.social .preview iframe {
            transform-origin: 0 0;
            transform: scale(0.5);
        }
    }
</style>