import { createStore } from 'vuex';
import { useWebWorkerFn } from '@vueuse/core';

export default createStore({
    state () {
        return {
            guest: false,
            authToken: null,
            loading: false,
            columns: [],
            rows: [],
            filters: { Active: true },
            filterOptions: {},
            divprod: {},
            prodseg: {},
            filterActive: { Active: true },
            viewType: 'social',
            sortMode: 'original',
            sortModes: [ 'original', 'size'  ]
        }
    },
    getters: {
        viewType: ({ viewType }) => viewType,
        sortModes: ({ sortModes }) => sortModes,
        active: ({ rows, filters, filterActive, filterOptions, sortMode, viewType }) => {
            if( viewType === 'dco' )
            {
                let unsorted = rows.filter( ( row ) => {
                    // eslint-disable-next-line
                    debugger;
                    return ( !filterActive.Adsize || ( filters.Adsize && filters.Adsize.indexOf( row.Adsize ) >= 0 ) ) && // adsize
                        ( !filterActive.Date || ( ( !filters.DateTo || new Date( row.Startdate ) < filters.DateTo ) && ( !filters.DateFrom || new Date( row.Enddate ) > filters.DateFrom ) ) ) && // division
                        ( !filterActive.Division || ( filters.Division && filters.Division.indexOf( row.Division ) >= 0 ) ) && // division
                        ( !filterActive.Segmentname || ( filters.Segmentname && filters.Segmentname.indexOf( row.Segmentname ) >= 0 ) ) && // segment
                        ( !filterActive.Product || ( filters.Product && filters.Product.indexOf( row.Modelnamelong ) >= 0 ) ) && // Modelname
                        ( !filterActive.CampaignPhase || ( filters.CampaignPhase && filters.CampaignPhase.indexOf( row.CampaignPhase ) >= 0 ) ) && // phase
                        ( !filterActive.Promo || ( filters.Promo && filters.Promo.indexOf( row.ReportingCustom2 ) >= 0 ) ) && // promo
                        ( !filterActive.Locale || ( filters.Locale && filters.Locale.indexOf( row.TriggerCustom2 ) >= 0 ) ) && // locale
                        ( !filterActive.UniqueID || ( filters.UniqueID && filters.UniqueID.indexOf( row.UniqueID ) >= 0 ) ) && // UniqueID
                        ( !filterActive.Status || ( filters.Status && filters.Status.indexOf( row.ProductCustom10 ) >= 0 ) ) && // development / accepted,
                        ( !filterActive.AnimationType || ( filters.AnimationType && ( filters.AnimationType.indexOf( row.LifestyleAnimationType ) >= 0 || filters.AnimationType.indexOf( row.ProductAnimationType ) >= 0 ) ) ) && // AnimationType
                        ( !filterActive.BackgroundType || ( filters.BackgroundType && filters.BackgroundType.indexOf( row.LifestyleCustom1 ) >= 0 ) ) &&
                        ( !filterActive.Active || ( filters.Active === 'TRUE' && row.Active === 'TRUE' && ( new Date( row.Startdate ) < new Date() && new Date( row.Enddate ) > new Date() ) ) || ( filters.Active === 'FALSE' && ( row.Active === 'FALSE' || new Date( row.Startdate ) > new Date() || new Date( row.Enddate ) < new Date() ) ) ) // Active
                });
                if( sortMode === 'original' )
                {
                    return unsorted;
                }
                let order = filterActive.Adsize && filters.Adsize && filters.Adsize.length > 0 ? filters.Adsize : filterOptions.Adsize;
                let sorted = [];
                for( let i in order )
                {
                    for( let j in unsorted )
                    {
                        if( unsorted[j].Adsize === order[i] )
                        {
                            sorted.push( unsorted[j] );
                        }
                    }
                }
                return sorted;
            } else {
                let unsorted = rows.filter( ( row ) => {
                    let divs = [];
                    let divsGrouped = {
                        'HAD': [ 'had', 'da_PR', 'damul_OB' ],
                        'AVD': [ 'avd', 'vd_PR', 'vdmul_OB', 'tv_SB' ],
                        'IMD': [ 'imd', 'im_PR', 'mocross_SB', 'immulti_OB', 'smart_SB' ],
                        'ESTORE': [ 'ESTORE' ]
                    };
                    for( let i in filters.Division )
                    {
                        divs = divs.concat( divsGrouped[ filters.Division[ i ] ] );
                    }
                    let prods = [];
                    let prodsGrouped = {
                        'BI': [ 'FF~BI' ],
                        'REF': [ 'FF~REF' ],
                        'HHP': [ 'Phones' ],
                        'AUDIO': [ 'Soundbar' ],
                        'TAB': [ 'TAB' ],
                        'TV': [ 'TV' ],
                        'VC': [ 'VC', 'VCC', 'VACUUM' ],
                        'WM': [ 'WM', 'WASH' ],
                        'WEA': [ 'WEA', 'PR~wearoth_' ]
                    };
                    for( let i in filters.Products )
                    {
                        prods = prods.concat( prodsGrouped[ filters.Products[ i ] ] );
                    }
                    let countries = [];
                    let languages = [];
                    for( let i in filters.Country )
                    {
                        let c = filters.Country[ i ].split( '_' );
                        countries.push( c[0] );
                        if( c[1] )
                        {
                           languages.push( c[1] );
                        }
                    }
                    let publishers = [];
                    let postTypes = [];
                    for( let i in filters.Publisher )
                    {
                        let c = filters.Publisher[ i ].split( '_' );
                        publishers.push( c[0] );
                        c.shift();
                        postTypes.push( c[0] === 'feed' || c[0] === 'instant' ? c.join('_') : filters.Publisher[ i ] );
                    }
                    console.log( row['Ad status'], row['Ad status'] === 'ACTIVE' );
                    return ( !filterActive.Search || ( filters.Search && row['Campaign name'] && row['Campaign name'].toLowerCase().indexOf( filters.Search.toLowerCase() ) >= 0 ) ) && // Campaign name
                        ( !filterActive.Search || ( filters.Search && row['Campaign name'] && row['Ad name'].toLowerCase().indexOf( filters.Search.toLowerCase() ) >= 0 ) ) && // ad name
                        ( !filterActive.Active || ( filters.Active === ( row['Ad status'] === 'ACTIVE' ) ) ) && // Active
                        ( !filterActive.Division || ( row.s1 && stringHasOneOf( row.s1, divs ) ) ) &&
                        ( !filterActive.Phases || ( stringHasOneOf( row['Campaign name'], filters.Phases ) ) ) &&
                        ( !filterActive.Products || ( stringHasOneOf( row['Campaign name'], prods ) ) ) &&
                        ( !filterActive.Country || ( stringHasOneOf( row['Account'], countries ) ) ) &&
                        ( !filterActive.Country || languages.length === 0 || ( stringHasOneOf( row['Ad name'], languages ) ) ) &&
                        ( !filterActive.Publisher || ( filters.Publisher && ( publishers.indexOf( row['Publisher platform'].toLowerCase() ) >= 0 ) && ( postTypes.indexOf( row['Platform position'].toLowerCase() ) >= 0 ) ) ) &&
                        ( !filterActive.ContentType || ( filters.ContentType && Object.values( filters.ContentType ).indexOf( row['Ad creative object type'].toLowerCase() ) >= 0 ) )
                });
                return unsorted;
            }
        },
        loading: ({ loading }) => loading,
        filters: ({ filters }) => filters,
        filterActive: ({ filterActive }) => filterActive,
        columnOptions: ({ filterOptions } ) => ( columns ) =>
        {
            let merged = [];
            if( typeof columns === 'string' )
            {
                merged = filterOptions[ columns ];
            }
            else {
                for( let i in columns )
                {
                    merged = merged.concat( filterOptions[ columns[i] ] );
                }
                merged = merged.filter( onlyUnique );
            }
            let opts = {};
            for( let i in merged )
            {
                opts[ merged[i] ] = merged[i];
            }
            return opts;
        },
        columnOptionsRelated: ( state ) => ( column, type, by ) =>
        {
            let opts = [];
            if( by.length < 1 ) return state.filterOptions[ column ];
            for( let i in by )
            {
                opts = opts.concat( Object.values( state[ type ][ by[i] ] ) );
            }
            let defOpts = {};
            for( let i in opts )
            {
                defOpts[ opts[i] ] = opts[i];
            }
            return defOpts;
        },
        authToken: ({ authToken }) => authToken,
        guest: ({ guest }) => guest
    },
    mutations: {
        async updateData( state, payload )
        {
            state.loading = true;
            let parsed = null;
            if( state.viewType === 'dco' )
            {
                parsed = await dcoParser.workerFn( payload );
            } else {
                parsed = await socialParser.workerFn( payload );
            }
            state.columns = parsed.columns;
            console.log( state.columns );
            state.rows = parsed.rows;
            console.log( state.rows );
            state.filterOptions = parsed.filterOptions;
            state.divprod = parsed.divprod;
            state.prodseg = parsed.prodseg;
            state.loading = false;
        },
        setFilter( state, payload )
        {
            for( let i in payload )
            {
                state.filters[ i ] = payload[ i ];
            }
        },
        setSort( state, payload )
        {
            state.sortMode = payload;
        },
        setFilterActive( state, payload )
        {
            for( let i in payload )
            {
                state.filterActive[ i ] = payload[ i ];
            }
        },
        authToken( state, payload )
        {
            state.authToken = payload;
        },
        loading( state, payload )
        {
            state.loading = payload;
        },
        guest( state, payload )
        {
            state.guest = payload;
        },
        setViewType( state, payload )
        {
            state.viewType = payload;
        }
    },
    actions: {
        async fetchData( context, payload )
        {
            let headers = {};
            if( payload.token )
            {
                context.commit( 'authToken', payload.token );
            }
            if( context.getters.authToken )
            {
                headers = { Authorization: 'Bearer ' + context.getters.authToken  };
            }

            context.commit( 'loading', true );
            context.commit( 'guest', !!payload.guest );
            const response = await fetch( window._rootData.apiUrl + '/data' + ( payload.force ? '/create' : '' ) + '?type=' + context.getters.viewType, { headers } );
            if( response.ok )
            {
                let data = await response.json();
                return context.dispatch( 'updateData', data.data );
            } else {
                context.commit( 'authToken', false );
                context.commit( 'loading', false );
            }
        },
        updateData( context, payload )
        {
            return context.commit( 'updateData', payload );
        },
        setFilter( context, payload )
        {
            context.commit( 'setFilter', payload );
        },
        setSort( context, payload )
        {
            context.commit( 'setSort', payload );
        },
        setFilterActive( context, payload )
        {
            context.commit( 'setFilterActive', payload );
        },
        setViewType( context, payload )
        {
            context.commit( 'setViewType', payload );
        },
        async saveView( context )
        {
            const formData = new FormData();
            formData.append( 'type', context.getters.viewType );
            formData.append( 'filters', JSON.stringify( { filters: context.getters.filters, filterActive: context.getters.filterActive } ) );
            const response = await fetch( window._rootData.apiUrl + '/data', { method: 'POST', body: formData, headers: { Authorization: 'Bearer ' + context.getters.authToken  } } );
            if( response.ok )
            {
                return await response.json();
            }
            return false;
        }
    }
});

function onlyUnique( value, index, self )
{
    return self.indexOf( value ) === index;
}
// worker
const dcoParser = useWebWorkerFn(parseDcoData);
const socialParser = useWebWorkerFn(parseSocialData);
function parseDcoData( payload )
{
    function onlyUnique( value, index, self )
    {
        return self.indexOf( value ) === index;
    }
    let returnData = {
        columns: payload.rows.splice( 0, 1 )[0],
        rows: [],
        filterOptions: []
    };
    for( let i in payload.rows )
    {
        let row = {};
        for( let j in returnData.columns )
        {
            row[ returnData.columns[j] ] = payload.rows[i][j]; // map to object
            if( !(returnData.filterOptions[ returnData.columns[j] ] instanceof Array) )
            {
                returnData.filterOptions[ returnData.columns[j] ] = [];
            }
            returnData.filterOptions[ returnData.columns[j] ].push( payload.rows[i][j] ); // save for unique column options
        }
        returnData.rows.push( row );
    }
    returnData.divprod = payload.divprod;
    returnData.prodseg = payload.prodseg;
    for( let k in returnData.filterOptions )
    {
        returnData.filterOptions[ k ] = returnData.filterOptions[ k ].filter( onlyUnique );
    }
    return returnData;
}

function parseSocialData( payload )
{
    function onlyUnique( value, index, self )
    {
        return self.indexOf( value ) === index;
    }
    let returnData = {
        columns: payload.rows.splice( 0, 1 )[0],
        rows: [],
        filterOptions: []
    };
    for( let i in payload.rows )
    {
        let row = {};
        for( let j in returnData.columns )
        {
            if( returnData.columns[j] === 'Campaign name' )
            {
                let splittedCols = payload.rows[i][j].split( '~' );
                for( let k in splittedCols )
                {
                    row[ 's' + k ] = splittedCols[ k ]; // map to object
                    if( !(returnData.filterOptions[ 's' + k ] instanceof Array) )
                    {
                        returnData.filterOptions[ 's' + k ] = [];
                    }
                    returnData.filterOptions[ 's' + k ].push( splittedCols[ k ] );
                }
            }
            row[ returnData.columns[j] ] = payload.rows[i][j]; // map to object
            if( !(returnData.filterOptions[ returnData.columns[j] ] instanceof Array) )
            {
                returnData.filterOptions[ returnData.columns[j] ] = [];
            }
            returnData.filterOptions[ returnData.columns[j] ].push( payload.rows[i][j] ); // save for unique column options
        }
        returnData.rows.push( row );
    }
    returnData.divprod = payload.divprod;
    returnData.prodseg = payload.prodseg;
    for( let k in returnData.filterOptions )
    {
        returnData.filterOptions[ k ] = returnData.filterOptions[ k ].filter( onlyUnique );
    }
    return returnData;
}

function stringHasOneOf( str, strs )
{
    for( let i in strs )
    {
        if( str.toLowerCase().indexOf( strs[i].toLowerCase() ) >= 0 )
        {
            return true;
        }
    }
    return false;
}