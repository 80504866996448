<template>
    <main :class="{ 'nav-open': navOpen && ( auth || guest ) }">
        <nav v-if="( guest || auth ) && !loading">
            <div class="tabs">
                <div :class="{ tab: true, active: viewType === 'dco' }" @click.prevent="viewType = 'dco'">DCO</div>
                <div :class="{ tab: true, active: viewType === 'social' }" @click.prevent="viewType = 'social'">Social</div>
            </div>
            <v-dco-filters v-if=" viewType === 'dco' "></v-dco-filters>
            <v-social-filters v-if=" viewType === 'social' "></v-social-filters>
            
            <div>
                <button class="action" @click="fetchData">Apply filters</button>
            </div>
            <div v-if="!guest">
                <button class="action" @click="fetchData">Fetch feed</button>
                <button class="action" @click="saveView">Save view</button>
                <div v-show="view">Saved <a :href="view" target="_blank" style="color: white;">URL</a> in clipboard.</div>
            </div>

            <div id="preview-buttons" class="preview-buttons">
                <button id="toggle-panel-button" class="toggle" @click="toggleNav"><span></span></button>
            </div>
        </nav>
        <section>
            <img src="svg/logo.svg" alt="" class="logo" />
            <div v-if="auth || guest">
                <div v-if="loading" style="text-align: center;">Loading...</div>
                <div v-else>
                    <v-preview v-for="( preview, index ) of active" :key="preview.UniqueID + '' + ( preview['Ad name'] || preview['ReportingLabel'] )" :data="preview" :forceView="index < 10"></v-preview>
                </div>
            </div>
            <div class="auth-screen" v-else>
                <form action="/" @submit.prevent="login">
                    <h3>Authenticate</h3>
                    <input type="password" v-model="password">
                    <button type="submit">Login</button>
                </form>
            </div>
        </section>
    </main>
</template>

<script>
    import { ref, computed, watch } from 'vue';
    import { useStore } from 'vuex';
    import DcoFilters from './components/DcoFilters';
    import SocialFilters from './components/SocialFilters';
    import Preview from './components/Preview';
    import { useClipboard } from '@vueuse/core';
    export default {
        components: {
            VPreview: Preview,
            VDcoFilters: DcoFilters,
            VSocialFilters: SocialFilters
        },
        setup()
        {
            const store = useStore();
            let navOpen = ref( true );
            let password = ref( '' );
            let view = ref( '' );
            let viewType = ref( store.getters.viewType );
            const { copy } = useClipboard();

            watch( viewType,
                () => {
                    store.dispatch( 'setViewType', viewType );
                    store.dispatch( 'fetchData', {} );
                } );

            return {
                auth: computed( () => store.getters.authToken ),
                guest: computed( () => store.getters.guest ),
                password,
                login: () => {
                    store.dispatch( 'fetchData', { token: password.value } );
                },
                loading: computed( () => store.getters.loading ),
                active: computed( () => store.getters.active ),
                filters: computed( () => store.getters.filters ),
                viewType,
                navOpen,
                toggleNav: () => navOpen.value = !navOpen.value,

                view,
                saveView: () => { store.dispatch('saveView').then( ({ url }) => { copy( url ); view.value = url }) },
                
                reloadData: () => {
                    store.dispatch( 'fetchData', {} );
                },
                fetchData: () => {
                    store.dispatch( 'fetchData', { force: true } );
                }
            };
        }
    }
</script>

<style lang="scss">
    @import "@/assets/scss/settings";
    @import "@/assets/scss/app";

    .auth-screen {
        position: relative;
        display: flex;
        align-content: center;
        justify-content: center;
        form {
            background: white;
            box-shadow: 0px 0px 15px rgba( 0, 0, 0, 0.1 );
            padding: 1em;
            h3 {
                color: black;
                margin: 0;
            }
        }
    }

    nav {
        background:$grey;
        position:fixed;
        top:0;
        left:0;
        bottom:0;
        width:280px;

        color:white;

        transform: translateX( -280px );
        transition: transform 0.3s ease-in-out;
        z-index: 2;

        box-shadow: inset -6px 0px 6px rgba( 0, 0, 0, 0.3 );
        padding: 0.5em;
        .tabs {
            width :100%;
            .tab {
                background: transparent;
                border: 1px solid white;
                width: 50%;
                display: inline-block;
                text-align: center;
                cursor: pointer;
                &.active {
                    background: black;
                }
            }
        }
        .action {
            background-color: black;
            border: none;
            margin: 0.5em 0;
            padding: 0.5em;
            width: 100%;
            color: white;
            cursor: pointer;
            &:hover {
                background-color: white;
                color: black;
            }
        }
        .preview-buttons {
            position: absolute;
            top: 0;
            right: 0;
            text-align: center;
            transform-origin: 100% 0;
            transform: translateX( 100% );

            button{
                width:3rem;
                height:3rem;
                padding:0.2rem;
                background-color:$grey;
                border-left:1px solid $lightgrey;

                display:block;
                border-radius: 0 $radius $radius 0;
                transition: background-color 0.2s ease-in-out;
                cursor: pointer;

                &.toggle{
                    span{
                        width:1rem;
                        height:1rem;
                        border-top:0.2rem solid white;
                        border-left:0.2rem solid white;
                        display:inline-block;
                        transform: translateX(-0.2rem)  scale(-1,1) rotate(-45deg);
                        transition: transform 0.2s ease-in-out;
                    }
                }
            }
        }
    }
    section {
        padding: 75px 1em 1em 1em;
        .logo {
            position: absolute;
            top: 1em;
            right: 1em;
            max-width: 50vw;
        }
    }
    nav {
        transition: transform 0.3s ease-in-out;
    }
    
    .nav-open {
        max-width: 100vw;
        min-height: 100vh;
        overflow: hidden;
        nav {
            transform: translateX( 0 );
            .preview-buttons{
                .toggle{
                    span{
                        transform: translateX(0.2rem) scale(1,-1) rotate(-45deg);
                    }
                }
            }
        }
        section {
            // transform: translateX( 250px );
        }
    }

    @media (min-width: 1024px) {
        nav .preview-buttons {
            display: none;
        }
        .nav-open section {
            margin-left: 270px;
        }
    }

</style>
