import { createApp } from 'vue';
import store from './store';

import App from './App.vue';

store.dispatch( 'setViewType', window._rootData.type );

if( window._rootData.guest )
{
    store.dispatch( 'fetchData', { guest: window._rootData.guest });
}

if( window._rootData.filters )
{
    if( !window._rootData.filters.filters )
    {
        store.dispatch( 'setFilter', window._rootData.filters );
        let activate = {};
        for( let i in window._rootData.filters )
        {
            if( window._rootData.filters[i].length > 0 )
            {
                activate[ i ] = true;
            }
        }
        store.dispatch( 'setFilterActive', activate );
    } else {
        store.dispatch( 'setFilter', window._rootData.filters.filters );
        store.dispatch( 'setFilterActive', window._rootData.filters.filterActive );
    }
}

createApp(App)
    .use(store)
    .mount('#app');
