<template>
    <div class="filters">
        <filter-activator label="Adsize" v-model="filterActive.Adsize">
            <multiselect v-model="adsizes" :options="adsizeOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Date range" v-model="filterActive.Date">
            <datepicker v-model="from" :upperLimit="to" class="datepick" />
            <datepicker v-model="to" :lowerLimit="from" class="datepick" />
        </filter-activator>
        <filter-activator label="Division" v-model="filterActive.Division">
            <multiselect v-model="divisions" :options="divisionOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Product" v-model="filterActive.Product">
            <multiselect v-model="products" :options="productOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Segment" v-model="filterActive.Segmentname">
            <multiselect v-model="segments" :options="segmentOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Phase" v-model="filterActive.CampaignPhase">
            <multiselect v-model="phases" :options="phaseOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Promo" v-model="filterActive.Promo">
            <multiselect v-model="promos" :options="promoOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Language" v-model="filterActive.Locale">
            <multiselect v-model="locales" :options="localeOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Status" v-model="filterActive.Status">
            <multiselect v-model="statusses" :options="statusOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <filter-activator label="Is live" v-model="filterActive.Active">
            <toggle v-model="active" id="active" trueValue="TRUE" falseValue="FALSE" />
        </filter-activator>
        <filter-activator label="Unique ID" v-model="filterActive.UniqueID">
            <multiselect v-model="uniqueids" :options="uniqueidOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <h3>Inspiration</h3>
        <filter-activator label="Animation" v-model="filterActive.AnimationType">
            <multiselect v-model="animations" :options="animationOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator>
        <!-- filter-activator label="Background types" v-model="filterActive.BackgroundType">
            <multiselect v-model="backgrounds" :options="backgroundOptions" mode="tags" :maxHeight="320" :searchable="true"></multiselect>
        </filter-activator -->
        <div class="info">
            {{ banners.length }} matches<br />
        </div>
        <label for="sort">Sort ({{ sort }}):&nbsp;<toggle v-model="sort" id="sort" trueValue="original" falseValue="size" /></label>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect';
    import Toggle from '@vueform/toggle';
    import Datepicker from 'vue3-datepicker';
    import FilterActivator from '@/components/FilterActivator';
    import { ref, watch, computed } from 'vue';
    import { useStore } from 'vuex';
    export default {
        components: {
            Datepicker,
            Toggle,
            Multiselect,
            FilterActivator
        },
        /* props: {
            filters: {
                type: Array,
                default: () => { return []; }
            }
        }, */
        setup()
        {
            const from = ref(new Date(''));
            const to = ref(new Date());
            const store = useStore();

            const adsizes = ref( store.getters.filters.Adsize );
            const adsizeOptions = computed( () => store.getters.columnOptions('Adsize') );
            const divisions = ref( store.getters.filters.Division );
            const divisionOptions = computed( () => store.getters.columnOptions('Division', true ) );
            const products = ref( store.getters.filters.Product );
            const productOptions = computed( () => {
                let opts = [];
                for( let i in divisions.value )
                {
                    opts.push( divisionOptions.value[ divisions.value[i] ] );
                }
                return store.getters.columnOptionsRelated( 'Modelnamelong', 'divprod', opts );
            });
            const segments = ref( store.getters.filters.Segmentname );
            const segmentOptions = computed( () => {
                let opts = [];
                for( let i in products.value )
                {
                    opts.push( productOptions.value[ products.value[i] ] );
                }
                return store.getters.columnOptionsRelated( 'Segmentname', 'prodseg', opts );
            });
            const phases = ref( store.getters.filters.CampaignPhase );
            const phaseOptions = computed( () => store.getters.columnOptions('CampaignPhase') );
            const promos = ref( store.getters.filters.Promo );
            const promoOptions = computed( () => store.getters.columnOptions('ReportingCustom2') );
            const uniqueids = ref( store.getters.filters.UniqueID );
            const uniqueidOptions = computed( () => store.getters.columnOptions('UniqueID', true ) );

            const locales = ref( store.getters.filters.Locale );
            const localeOptions = computed( () => store.getters.columnOptions( 'TriggerCustom2' ) );
            const statusses = ref( store.getters.filters.Status );
            const statusOptions = computed( () => store.getters.columnOptions( 'ProductCustom10' ) );
            const active = ref( store.getters.filters.Active );

            const animations = ref( store.getters.filters.AnimationType );
            const animationOptions = computed( () => store.getters.columnOptions( [ 'LifestyleAnimationType', 'ProductAnimationType' ] ) );
            const backgrounds = ref( store.getters.filters.BackgroundType );
            const backgroundOptions = computed( () => store.getters.columnOptions( 'LifestyleCustom1' ) );
            const sort = ref( store.getters.filters.BackgroundType );
            const sortOptions = computed( () => store.getters.sortModes );

            const filterActive = computed( () => store.getters.filterActive );

            watch( filterActive,
                ( value ) => {
                    let filter = {};
                    for( let i in value )
                    {
                        console.log(i,  value[i] );
                        filter[ i ] = value[i];
                    }
                    store.dispatch( 'setFilterActive', filter );
                }, { deep: true });

            watch( adsizes,
                () => {
                    let filter = [];
                    for( let i in adsizes.value )
                    {
                        filter.push( adsizeOptions.value[ adsizes.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        Adsize: filter
                    });
                } );

            watch( from,
                ( value ) => {
                    store.dispatch( 'setFilter', {
                        DateFrom: new Date( value )
                    });
                } );
            watch( to,
                ( value ) => {
                    store.dispatch( 'setFilter', {
                        DateTo: new Date( value )
                    });
                } );

            watch( divisions,
                () => {
                    let filter = [];
                    for( let i in divisions.value )
                    {
                        filter.push( divisionOptions.value[ divisions.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        Division: filter
                    });
                } );

            watch( segments,
                () => {
                    let filter = [];
                    for( let i in segments.value )
                    {
                        filter.push( segmentOptions.value[ segments.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        Segmentname: filter
                    });
                } );

            watch( products,
                () => {
                    let filter = [];
                    for( let i in products.value )
                    {
                        filter.push( productOptions.value[ products.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        Product: filter
                    });
                } );

            watch( phases,
                () => {
                    let filter = [];
                    for( let i in phases.value )
                    {
                        filter.push( phaseOptions.value[ phases.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        CampaignPhase: filter
                    });
                } );

            watch( promos,
                () => {
                    let filter = [];
                    for( let i in promos.value )
                    {
                        filter.push( promoOptions.value[ promos.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        Promo: filter
                    });
                } );

            watch( uniqueids,
                () => {
                    let filter = [];
                    for( let i in uniqueids.value )
                    {
                        filter.push( uniqueidOptions.value[ uniqueids.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        UniqueID: filter
                    });
                } );

            watch( locales,
                () => {
                    let filter = [];
                    for( let i in locales.value )
                    {
                        filter.push( localeOptions.value[ locales.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        Locale: filter
                    });
                });

            watch( statusses,
                () => {
                    let filter = [];
                    for( let i in statusses.value )
                    {
                        filter.push( statusOptions.value[ statusses.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        Status: filter
                    });
                });

            watch( active,
                () => {
                    store.dispatch( 'setFilter', {
                        Active: active.value
                    });
                });

            watch( animations,
                () => {
                    let filter = [];
                    for( let i in animations.value )
                    {
                        filter.push( animationOptions.value[ animations.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        AnimationType: filter
                    });
                });

            watch( backgrounds,
                () => {
                    let filter = [];
                    for( let i in backgrounds.value )
                    {
                        filter.push( backgroundOptions.value[ backgrounds.value[i] ] );
                    }
                    store.dispatch( 'setFilter', {
                        BackgroundType: filter
                    });
                });
            watch( sort,
                () => {
                    store.dispatch( 'setSort', sort );
                });
            
            return {
                banners: computed( () => store.getters.active ),
                from,
                to,
                filterActive,
                adsizes,
                adsizeOptions,
                divisions,
                divisionOptions,
                segments,
                segmentOptions,
                products,
                productOptions,
                phases,
                phaseOptions,
                promos,
                promoOptions,
                uniqueids,
                uniqueidOptions,
                locales,
                localeOptions,
                statusses,
                statusOptions,
                active,
                animations,
                animationOptions,
                backgrounds,
                backgroundOptions,
                sort,
                sortOptions
            };
        }
    }
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style src="@vueform/toggle/themes/default.css"></style>
<style lang="scss">
    @import "@/assets/scss/settings";

    .datepick {
        width: 100%;
    }

    h3 {
        text-align: left;
        font-weight: normal;
        padding: 0.5em;
        margin: 0;
        margin-top: 1em;
        color: #bbb;
    }

    .info {
        padding: 0.5em 1em 0.5em 0.5em;
        color: #666;
        text-align: center;
    }

    .filter {
        position:relative;
        &>label {
            border-bottom: 1px solid #666;
            width: 100%;
            display: inline-block;
            margin-bottom: 0.25em;
        }
        &>div {
            display: none;
        }
        &:before {
            content: '+';
            position: absolute;
            top: 0.5em;
            right: 0.5em;
        }
        &.active {
            &:before {
                content: '-';
            }
            &>div {
                display:block;
            }
        }
        input {
            background: transparent;
            color: white;
            border: 1px solid white;
            border-radius: 5px;
            padding: 0.25em;
        }
    }
</style>